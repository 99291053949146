import axios from "axios";
import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { backend } from "../../config/api";
import Category from "../reuse/Category";

const CategoryInfo = () => {
  const { category } = useParams();
  const [categories, setCategories] = useState([]);
  const controller = useRef(null);

  useEffect(() => {
    controller.current?.abort();
    controller.current = new AbortController();
    axios
      .get(
        backend(`/api/category/${category}`, {
          signal: controller.current.signal,
        })
      )
      .then((response) => {
        setCategories(response.data);
      })
      .catch(() => {});

    return () => {
      controller.current?.abort();
    };
  }, [category]);

  return (
    <React.Fragment>
      <div style={{ height: 1 }}></div>
      {categories &&
        categories.map((category) => (
          <Category category={category} key={category.id} showAll={true} />
        ))}
    </React.Fragment>
  );
};

export default CategoryInfo;
