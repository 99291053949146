import React from "react";
import "./ImportantDocuments.css";

const file = (name, file) => ({
  name: name,
  link: `/files/${file}`,
});

const downloadList = [
  file("1. หนังสือจดทะเบียนรถใหม่ (ด้านหน้า)", "doc1.pdf"),
  file("2. หนังสือจดทะเบียนรถใหม่ (ด้านหลัง)", "doc2.pdf"),
  file("3. หนังสือโอน (ด้านหน้า)", "doc3.pdf"),
  file("4. หนังสือโอน (ด้านหลัง)", "doc4.pdf"),
  file("5. หนังสือมอบอำนาจ (ด้านหน้า)", "doc5.pdf"),
  file("6. หนังสือมอบอำนาจ (ด้านหลัง)", "doc6.pdf"),
  file("7. หนังสือยินยอม", "doc7.pdf"),
  file("8. หนังสือยินยอม (กรณีรถเข้าไฟแนนซ์)", "doc8.pdf"),
  file("9. เอกสารโอนสิทธิ์ (ป้ายประมูล)", "doc9.pdf"),
  file("10. เอกสารแบบคำขออื่นๆ", "doc10.pdf"),
];

const ImportantDocuments = () => {
  return (
    <>
      <div style={{ height: "1px" }}></div>
      <div className="details-header">
        <h2>เอกสารสำคัญ</h2>
      </div>
      <div className="important-documents">
        <div className="document-content">
          <h3>เอกสารประกอบการจดทะเบียนรถใหม่</h3>
          <p>สำเนาทะเบียนบ้าน (พร้อมรับรองสำเนา)</p>
          <p>สำเนาบัตรประจำตัวประชาชน 2 ชุด (พร้อมรับรองสำเนา)</p>
          <p>สำเนาบัตรประจำตัวประชาชนของเจ้าของสิทธิเลขหมายประมูล</p>
          <p>หนังสือรับรองการโอนสิทธิหมายเลขทะเบียนที่ประมูลได้</p>
          <p>
            หนังสือแสดงสิทธิเลขหมายทะเบียน (ใบเสร็จรับเงินของกรมการขนส่งทางบก)
          </p>
          <p>สมุดทะเบียนรถ</p>
          <p>เอกสารชุดจดทะเบียนรถป้ายแดง</p>

          <ul>
            {downloadList.map((item, index) => {
              const ext = item.link.split(".").pop();
              const downloadname = `${item.name}.${ext}`;
              return (
                <li key={index}>
                  {item.name}
                  <a
                    href={item.link}
                    target="_blank"
                    className="btn"
                    rel="noreferrer"
                    download={downloadname} // ใช้ชื่อเอกสารในภาษาไทยเป็นชื่อไฟล์ดาวน์โหลด
                  >
                    ดาวน์โหลด
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ImportantDocuments;
