import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/reuse/Header';
import Footer from './components/reuse/Footer';
import Index from './components/pages/Index';
import PlateDetails from './components/pages/PlateDetails';
import CategoryInfo from './components/pages/CategoryInfo';
import Documents from './components/pages/ImportantDocuments';
import BackToTop from './components/pages/BackToTop';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/ibm-plex-sans-thai-looped";
import "@fontsource/ibm-plex-sans-thai-looped/100.css";
import "@fontsource/ibm-plex-sans-thai-looped/200.css";
import "@fontsource/ibm-plex-sans-thai-looped/300.css";
import "@fontsource/ibm-plex-sans-thai-looped/400.css";
import "@fontsource/ibm-plex-sans-thai-looped/500.css";
import "@fontsource/ibm-plex-sans-thai-looped/600.css";
import "@fontsource/ibm-plex-sans-thai-looped/700.css";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="screen">
        <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/plate-details/:plate_id" element={<PlateDetails />} />
            <Route path="/important-documents" element={<Documents />} />
            <Route path="/category/:category" element={<CategoryInfo />} />
        </Routes>
        </div>
        <BackToTop />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
