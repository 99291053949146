import React, { useState } from "react";
import MainContent from "../reuse/MainContent";
import SearchSection from "./SearchSection";
import Category from "../reuse/Category";

const Index = () => {
  const [categories, setCategories] = useState([]);

  return (
    <React.Fragment>
      <MainContent />
      <SearchSection setCategories={setCategories} />
      {categories &&
        categories.map((category) => (
          <Category category={category} key={category.id} />
        ))}
    </React.Fragment>
  );
};

export default Index;
