import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import resolvePlateImage from "../../utils/resolvePlateImage";
import CTooltip from "./CTooltip";
import { BsInfoCircle } from "react-icons/bs";
import tbNum from "../../utils/tbNum";

const CardPlate = (prop) => {
  const { plate } = prop;
  const navigate = useNavigate();
  const controller = useRef(null);

  useEffect(() => {
    controller.current?.abort();
    controller.current = new AbortController();

    return () => {
      controller.current?.abort();
    };
  }, [plate.plateid]);

  const handlePlateClick = () => {
    navigate(`/plate-details/${plate.plateid}`);
  };

  // const InfoSum = "ผลรวมเลขศาสตร์ " + plate.luck_sum + (plate.grade !== "" ? " (" + plate.grade + ")" : "");
  const InfoSum = "ผลรวมเลขศาสตร์ " + plate.plate_sum;

  return (
    <div className="plate-card" onClick={() => handlePlateClick(plate)}>
      <div className={`plate-image ${plate.status === "SOLD" ? "sold" : ""}`}>
        <img src={resolvePlateImage(plate)} alt="Plate" />
        <div className="plate-overlay">
          {plate.status === "SOLD" && (
            <span className="badge bg-danger position-absolute bottom-0 end-0 m-2">
              ขายแล้ว
            </span>
          )}
          <div
            className={`plate-text ${
              plate.status === "SOLD" ? "sold-text" : ""
            } d-flex flex-column justify-content-center align-items-center`}
          >
            <div className="text-center hide-on-small-screen">
              <p className="invisible" style={{ height: "5px" }}>
                KKK
              </p>
            </div>
            <div className="text-center">
              <span>{plate.plate_char}</span> <span>{plate.plate_number}</span>
            </div>
            <div className="text-center">
              <p className="plate-province">
                {plate.plate_province !== "" ? (
                  plate.plate_province
                ) : (
                  <span>กรุงเทพมหานคร</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
        <p className="plate-price">
          <span className="price-label">ราคา</span>{" "}
          {plate.plate_price === 0
            ? "ติดต่อสอบถาม"
            : tbNum(plate.plate_price) + " บาท"}
        </p>
      <div className="" style={{ border: '1px solid #978B63',borderRadius:'0 0 3px 3px' }}>
        {plate.categoryname && (
          <>
            <p className="plate-name">{plate.categoryname}</p>
            <hr
              className="mt-1 mb-1 w-75 mx-auto"
              style={{
                border: "none",
                borderTop: "2px solid #978B63",
              }}
            />
          </>
        )}
        <p className="plate-name">
          <CTooltip tooltip={plate.predictions}>
            <span className="info-sum">{InfoSum}</span>
            <BsInfoCircle className="info-icon" />
          </CTooltip>
        </p>
      </div>
    </div>
  );
};

export default CardPlate;
