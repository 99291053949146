import React from "react";
import "./MainContent.css";
import headerImage from "../../assets/logo/header1.png";
import logo from "../../assets/logo/logo.png";

const MainContent = () => {
  return (
    <section className="main-content">
      <div className="image-container">
        <img src={headerImage} alt="Car" />
      </div>
      <div className="content-wrapper">
        <img className="logo-header" src={logo} alt="logo" />
        <h2>ยินดีต้อนรับ</h2>
        <h2>รับซื้อ-ขาย-จอง-รับจำนำ-ประมูล</h2>
        <h2>ทะเบียนรถ</h2>
      </div>
    </section>
  );
};

export default MainContent;
