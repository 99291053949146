import React, { useState, useRef, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import "./SearchSection.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { backend } from "../../config/api";

const SearchSection = (props) => {
  const setCategories = props.setCategories;
  const [loaded, setLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({
    plate_number: "",
    char_letter: "",
    char_number: "",
    plate_sum: "",
  });

  const controller = useRef(null);
  const [search, setSearch] = useSearchParams();
  const { pathname } = useLocation();

  useEffect(() => {
    const plate_number = search.get("plate_number");
    const char_number = search.get("char_number");
    const char_letter = search.get("char_letter");
    const plate_sum = search.get("plate_sum");

    setFormData({
      plate_number: plate_number || "",
      char_number: char_number || "",
      char_letter: char_letter || "",
      plate_sum: plate_sum || "",
    });

    let query = search.toString();
    setSearchTerm(query);
    setLoaded(true);
  }, [pathname, search]);

  useEffect(() => {
    if (!loaded) {
      return;
    }

    controller.current?.abort();
    controller.current = new AbortController();

    if (searchTerm === "") {
      axios
        .get(backend("/api/plate"), {
          signal: controller.signal,
        })
        .then((cat) => {
          setCategories(cat.data);
        })
        .catch((error) => {
          if (error.name !== "CanceledError") {
            console.error("Error fetching data:", error);
          }
        });
    } else {
      axios
        .get(backend("/api/plate/filter"), {
          params: {
            plate_number: formData.plate_number,
            char_letter: formData.char_letter,
            char_number: formData.char_number,
            plate_sum: formData.plate_sum,
          },
          signal: controller.signal,
        })
        .then((response) => {
          const tabiens = response.data;
          let categoryname = "ผลการค้นหา ";
          if (formData.char_number) {
            categoryname += `ชุด ${formData.char_number}`;
          }
          if (formData.char_letter) {
            categoryname += ` ตัวอักษร ${formData.char_letter}`;
          }
          if (formData.plate_number) {
            categoryname += ` เลขทะเบียน ${formData.plate_number}`;
          }
          if (formData.plate_sum) {
            categoryname += ` เลขผลรวม ${formData.plate_sum}`;
          }
          const category = {
            id: 0,
            categoryname: categoryname,
            tabiens: tabiens,
          };
          const categoryList = [category];

          setCategories(categoryList);
        })
        .catch((error) => {
          if (error.name !== "CanceledError") {
            console.error("Error fetching data:", error);
          }
        });
    }

    return () => {
      controller.current?.abort();
    };
    // eslint-disable-next-line
  }, [searchTerm, setCategories, loaded]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let search = {};
    if (formData.plate_number) {
      search.plate_number = formData.plate_number;
    }
    if (formData.char_number) {
      search.char_number = formData.char_number;
    }
    if (formData.char_letter) {
      search.char_letter = formData.char_letter;
    }
    if (formData.plate_sum) {
      search.plate_sum = formData.plate_sum;
    }
    setSearch(search);
  };

  return (
    <section className="container-fluid ">
      <div className="search-section ">
        <h3 className="text-center my-2">รายการค้นหาป้ายทะเบียน</h3>
        <div className="button-group d-flex justify-content-center mb-4">
          {/* <button className="btn btn-primary mx-2">
            <i className="bi bi-heart-fill me-0 me-md-2"></i>
            <span className="d-none d-md-inline">เสริมเรื่องความรัก</span>
          </button>
          <button className="btn btn-primary mx-2">
            <i className="bi bi-cash me-0 me-md-2"></i>
            <span className="d-none d-md-inline">เสริมเรื่องร่ำรวย</span>
          </button>
          <button className="btn btn-primary mx-2">
            <i className="bi bi-award-fill me-0 me-md-2"></i>
            <span className="d-none d-md-inline">เสริมเรื่องการงาน</span>
          </button> */}
        </div>
        <form
          className="row g-3 justify-content-center align-items-center mb-2"
          onSubmit={handleSubmit}
        >
          <div className="col-12 col-md-4 col-lg-2 d-flex align-items-center">
            <label htmlFor="char_number" className="form-label mb-0 me-3">
              เลขนำหน้า
            </label>
            <input
              type="text"
              className="form-control"
              id="char_number"
              value={formData.char_number}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-4 col-lg-2 d-flex align-items-center">
            <label htmlFor="char_letter" className="form-label mb-0 me-3">
              ตัวอักษร
            </label>
            <input
              type="text"
              pattern="[A-Za-zก-๙]+"
              className="form-control"
              id="char_letter"
              value={formData.char_letter}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-4 col-lg-2 d-flex align-items-center">
            <label htmlFor="plate_number" className="form-label mb-0 me-3 ">
              ตัวเลข
            </label>
            <input
              type="text"
              className="form-control"
              id="plate_number"
              value={formData.plate_number}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-4 col-lg-2 d-flex align-items-center">
            <label htmlFor="plate_sum" className="form-label mb-0 me-3 ">
              เลขผลรวม
            </label>
            <input
              type="text"
              className="form-control"
              id="plate_sum"
              value={formData.plate_sum}
              onChange={handleChange}
            />
          </div>
          <div className=" col-12 col-md-4 col-lg-2 align-self-end">
            <button
              style={{ color: "white" }}
              type="submit"
              className="serch-text btn btn_search w-100"
            >
              ค้นหา
              <span className="serch-text d-inline d-md-none d-xl-inline">
                ป้ายทะเบียน
              </span>
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default SearchSection;
