import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import logo from "../../assets/logo/logo.png";
import axios from "axios";
import { backend } from "../../config/api";
import { Link } from "react-router-dom";
import { FaLine } from "react-icons/fa6";

const Header = () => {
  const controller = useRef(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    require("bootstrap/dist/js/bootstrap");
    controller.current?.abort();
    controller.current = new AbortController();

    axios
      .get(backend("/api/category"), { signal: controller.current.signal })
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        if (error.name !== "CanceledError") {
          console.error("Fetch Error:", error);
        }
      });

    return () => {
      controller.current?.abort();
    };
  }, []);

  return (
    <header className="navbar shadow navbar-expand-lg fixed-top">
      <div className="container-fluid justify-content-between">
        <Link to="/" className="d-flex align-items-center">
          <img
            src={logo}
            alt="LUCKY TABIEN"
            className="brand-logo"
            // style={{ paddingLeft: "50px" }}
          />
        </Link>

        <div className="contact-none d-flex flex-column" style={{fontWeight:"bold"}}>
          <span className="contact-number2 mx-2">บูม: 091-119-9999</span>
          <span className="contact-number2 mx-2">เอ๋ย: 065-183-1111</span>
        </div>

        <div className="nav-link-container">
            <Link
              className="nav-link mx-2 logobig"
              to="https://line.me/R/ti/p/@boomluckytabien?from=page&accountId=boomluckytabien"
            >
              <FaLine fontSize={32} />
            </Link>
          </div>

        <button
          className="burger-btn me-4"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <span className="burger-icon"></span>
          <span className="burger-icon"></span>
          <span className="burger-icon"></span>
        </button>
        <div
          id="offcanvasRight"
          className="offcanvas offcanvas-end "
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header">
            {/* <h5 id="offcanvasRightLabel">Menu</h5> */}
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <Link className="nav-link mx-2" to="/">
              หน้าแรก
            </Link>
            <div className="dropdown mx-2">
              <a
                className="nav-link dropdown-toggle mx-1"
                href="#license"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                ทะเบียน
              </a>
              <ul
                className="dropdown-menu custom-dropdown"
                aria-labelledby="navbarDropdown"
              >
                {categories.map((category) => (
                  <li key={category.id}>
                    <Link
                      to={`/category/${category.id}`}
                      className="dropdown-item"
                    >
                      {category.categoryname}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <Link className="nav-link mx-2" to="/important-documents">
              เอกสารสำคัญ
            </Link>
            <hr />
            <div
              className="vr d-none d-md-inline ms-2 bg-white opacity-100"
              style={{ width: 2 }}
            ></div>
            <div className="d-flex align-items-center justify-content-center mx-2">
              <span className="contact-number mx-2">บูม: 091-119-9999</span>
              <span className="contact-number mx-2">เอ๋ย: 065-183-1111</span>

              <div className="nav-link-container">
                <Link
                  className="nav-link mx-2"
                  to="https://line.me/R/ti/p/@boomluckytabien?from=page&accountId=boomluckytabien"
                >
                  <FaLine fontSize={32} />
                </Link>
              </div>
            </div>
            {/* <div className="d-flex align-items-center justify-content-center mx-2">
              <span className="contact-number mx-2">บูม: 091-119-9999</span>
              <span className="contact-number mx-2">เอ๋ย: 065-183-1111</span>

              <div className="nav-link-container">
                <Link
                  className="nav-link mx-2"
                  to="https://line.me/R/ti/p/@boomluckytabien?from=page&accountId=boomluckytabien"
                >
                  <FaLine fontSize={32} />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
