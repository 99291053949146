import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import "./PlateDetails.css";
import axios from "axios";
import { backend } from "../../config/api";
import resolvePlateImage from "../../utils/resolvePlateImage";
import Category from "../reuse/Category";
import qrCodeImage from "../../assets/logo/qr.png";
import CTooltip from "../reuse/CTooltip";
import { BsInfoCircle } from "react-icons/bs";
import tbNum from "../../utils/tbNum";

const PlateDetails = () => {
  const { plate_id } = useParams();
  const controller = useRef(null);
  const [plate, setPlate] = useState({});

  useEffect(() => {
    controller.current?.abort();
    controller.current = new AbortController();

    axios
      .get(backend(`/api/plate/${plate_id}`), {
        signal: controller.current.signal,
      })
      .then((response) => {
        setPlate(response.data);
        window.scrollTo(0, 0);
        // console.log(response.data);
      })
      .catch((error) => {
        if (error.name !== "CanceledError") {
          console.log("Error:", error.message);
        }
      });

    return () => {
      controller.current?.abort();
    };
  }, [plate_id]);

  if (!plate.info) {
    return "";
  }

  return (
    <>
      <div style={{ height: "1px" }}></div>
      <div className="details-header">
        <h2>รายละเอียดทะเบียน</h2>
      </div>
      <div className="plate-details">
        <div className="plate-details-container">
          <div className="left-section">
            <div className="plate-image-container">
              <div className="plate">
                <img
                  src={resolvePlateImage(plate.info)}
                  alt={`${plate.info.plate_char} ${plate.info.plate_number}`}
                />
                <div className="plate-text-overlay mt-4">
                  {plate.info.plate_char} {plate.info.plate_number}
                  <p className="mb-0 mt-5 detail-province">
                    {plate.info.plate_province || "กรุงเทพมหานคร"}
                  </p>
                </div>
              </div>
            </div>
            <div className="contact-info d-flex justify-content-center ">
              <img src={qrCodeImage} alt="QR Code" className="qr-code " />
              <div className="vr d-none d-md-inline mx-5 bg-white opacity-100"></div>
              <div className="contact-text ">
                <p>สนใจติดต่อ</p>
                <p>
                  {" "}
                  <i className="bi bi-telephone-fill"></i> 091-119-9999{" "}
                </p>
                <p>
                  {" "}
                  <i className="bi bi-telephone-fill"></i> 065-183-1111{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="right-section">
            <div className="plate-info">
              <div className="info-section">
                <span className="info-label">หมวดอักษร</span>
                <span className="info-value">{plate.info.plate_char}</span>
              </div>
              <div className="info-section">
                <span className="info-label">เลขทะเบียน</span>
                <span className="info-value">{plate.info.plate_number}</span>
              </div>
              <div className="info-section">
                <span className="info-label">ทะเบียน</span>
                <span className="info-value">
                  {plate.info.plate_char} {plate.info.plate_number}
                </span>
              </div>
              <div className="info-section">
                <span className="info-label">หมวดหมู่ทะเบียน</span>
                <span className="info-value">{plate.info.categoryname}</span>
              </div>
              <div className="info-section">
                <span className="info-label">ชนิดป้าย</span>
                <span className="info-value">{plate.info.plate_type}</span>
              </div>
              <CTooltip tooltip={plate.info.predictions}>
                <div className="info-section">
                  <span className="info-label">ผลรวมเลขศาสตร์</span>
                  <span className="info-value">
                    {plate.info.plate_sum}
                    <BsInfoCircle
                      style={{
                        marginBottom: 4,
                        marginLeft: 3,
                        opacity: 0.5,
                      }}
                    />
                  </span>
                </div>
              </CTooltip>
              <div className="info-section1">
                <span className="info-label">ราคา</span>
                <span className="info-value">
                  {plate.info.plate_price === 0
                    ? "สนใจติดต่อสอบถาม"
                    : `${tbNum(plate.info.plate_price)} บาท`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="suggestions">
        <Category
          category={{
            categoryname: `ทะเบียนอื่นๆอักษร ${plate.info.plate_char}`,
            tabiens: plate.similar_char,
          }}
        />
        <Category
          category={{
            categoryname: `ทะเบียนอื่นๆเลข ${plate.info.plate_number}`,
            tabiens: plate.similar_number,
          }}
        />
      </div>
    </>
  );
};

export default PlateDetails;
