import React from "react";
import { Tooltip } from "@mui/material";

const CTooltip = ({ label, tooltip, children }) => (
  <Tooltip
    title={tooltip}
    followCursor
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: "#978B63", // กำหนดพื้นหลังเป็นสีแดง
          color: "white", // กำหนดสีตัวอักษรเป็นสีขาวเพื่อให้อ่านง่าย
          fontSize: "16px", // ขนาดตัวอักษรใน tooltip
          borderRadius: "4px", // ความโค้งมนของมุม tooltip
          p: 2, // padding ภายใน tooltip
          minWidth: "150px", // กำหนดความกว้างขั้นต่ำเพื่อชิดขอบซ้ายขวา
          maxWidth: "400px", // กำหนดความกว้างสูงสุดเพื่อไม่ให้ tooltip กว้างเกินไป
          textAlign: "center", // จัดข้อความให้อยู่ตรงกลาง
          border: "solid", //
        },
      },
    }}
  >
    <span>{label || children}</span>
  </Tooltip>
);

export default CTooltip;
