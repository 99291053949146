import plateWhite from "../assets/tabiens/white1.png";
import plateGold from "../assets/tabiens/bangkokGold.png";
import plateGreen from "../assets/tabiens/green_2.png";
import plateBlue from "../assets/tabiens/blue_2.png";
import bangkokPureple from "../assets/tabiens/bangkokPureple.png";
import chonburi from "../assets/tabiens/chonburi.png";
import khonkaen from "../assets/tabiens/khonkaen.png";
import nakhon_ratchasima from "../assets/tabiens/nakhon_ratchasima.png";
import nakhon_sawan from "../assets/tabiens/nakhon_sawan.png";
import phitsanulok from "../assets/tabiens/phitsanulok.png";
import songkhla from "../assets/tabiens/songkhla.png";
import nonthaburi from "../assets/tabiens/nonthaburi.png";
import phetburi from "../assets/tabiens/phetburi.png";
import buengkan from "../assets/tabiens/buengkan.png";
import samutprakarn from "../assets/tabiens/samutprakarn.png";
import chengmaiiNew from "../assets/tabiens/chengmaiiNew.png";

const resolvePlateProvince = (plate_province) => {
  switch (plate_province.trim()) {
    case "กทม." || "กทม":
      return bangkokPureple;
    case "ขอนแก่น" || "ขอนเเก่น":
      return khonkaen;
    case "ชลบุรี":
      return chonburi;
    case "นครราชสีมา":
      return nakhon_ratchasima;
    case "นครสวรรค์":
      return nakhon_sawan;
    case "นนทบุรี":
      return nonthaburi;
    case "บึงกาฬ":
      return buengkan;
    case "พิษณุโลก":
      return phitsanulok;
    case "สงขลา":
      return songkhla;
    case "สมุทรปราการ":
      return samutprakarn;
    case "เชียงใหม่":
      return chengmaiiNew;
    case "เพชรบุรี":
      return phetburi;
    default:
      return bangkokPureple;
  }
};

const resolvePlateImage = (plate) => {
  switch (plate.type) {
    case 1:
      return plateWhite;
    case 2:
    case 4:
    case 5:
      return resolvePlateProvince(plate.plate_province);
    case 3:
    case 6:
    case 7:
      return plateGold;
    case 8:
      return plateGreen;
    case 9:
      return plateBlue;
    default:
      return plateWhite;
  }
};
export default resolvePlateImage;
