import React, { useState } from "react";
import CardPlate from "./CardPlate";
import { Link } from "react-router-dom";

const Category = ({ category, showAll = false }) => {
  const minimizeItems = 16;
  const plates = category.tabiens || [];

  const [visible, setVisible] = useState(showAll ? plates.length : minimizeItems);

  const loadMore = () => {
    setVisible(plates.length);
  };

  const showLess = () => {
    setVisible(minimizeItems);
  };

  return (
    <section className="container-fluid">
      <div className="recommended-section">
        <div className="header">
          <Link
            to={category.id ? `/category/${category.id}` : "#"}
            className="h3 text-decoration-none text-white"
          >
            {category.categoryname}
          </Link>
          {!showAll && plates.length > minimizeItems && (
            <>
              {visible < plates.length ? (
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#8B8B8B",
                    fontWeight: "bold",
                  }}
                  onClick={loadMore}
                  className="toggle-button"
                >
                  ดูเพิ่มเติม
                </button>
              ) : (
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#8B8B8B",
                    fontWeight: "bold",
                  }}
                  onClick={showLess}
                  className="toggle-button"
                >
                  ดูน้อยลง
                </button>
              )}
            </>
          )}
        </div>
        <div className="plates-container">
          <div className="plates_itemcenter">
            {plates.length > 0 ? (
              plates.slice(0, visible).map((plate, index) => <CardPlate key={index} plate={plate} />)
            ) : (
              <p>ไม่มีข้อมูลแนะนำ</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Category;
