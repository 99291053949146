import React from "react";
import "./Footer.css";
import qrCode from "../../assets/logo/qr.png";
import logo from "../../assets/logo/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const Footer = () => {
  return (
    <section className="m-0 p-0">
      <footer className="footer ">
        <div className="footer-content container-fulid mx-4">
          <div className="row align-items-center">
            <div className="footer-text col-md-6 d-flex justify-content-md-start align-items-center">
              <img src={logo} alt="LUCKY TABIEN" className="brand-logo1" style={{width:"200px",height:"100px"}} />
              <div className="con_left">
                <p>ช่องทางการติดต่อ</p>
                <p>065-183-1111 k.เอ๋ย</p>
                <p>091-119-9999 k.บูม</p>
                <p>luckytabien7777@gmail.com</p>
                <div className="ps-2">
                  <div className="col-md-10">
                    <div className="ratio ratio-4x3">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.578480808686!2d100.55645469999999!3d13.804265400000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29d0074b220e9%3A0xdadee1180f5b7db5!2sLuckyTabien!5e0!3m2!1sth!2sth!4v1738653108016!5m2!1sth!2sth"
                        className="rounded shadow-sm"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-md-end align-items-center">
              <div className="footer-text">
                <p>ยินดีต้อนรับทุกท่านที่เข้ามาชมทะเบียนรถสวยเรามีป้าย</p>
                <p>ทะเบียนรถสวยให้ท่านเลือกมากมาย ทะเบียนรถมงคล</p>
                <p>เสริมบารมี โชคลาภ เงินทอง การค้ารุ่งเรือ ทะเบียนสวย</p>
                <p>ลัคกี้ทะเบียนขายทะเบียนรถ รับซื้อทะเบียนรถ</p>
              </div>
              <div className="footer-qr">
                <img src={qrCode} alt="QR Code" className="qr-code" />
              </div>
            </div>
          </div>
        </div>
      </footer>
      <p className="footer-bottom">
        ©2004-2024 www.luckytabien.com. All rights reserved.
      </p>
    </section>
  );
};

export default Footer;
